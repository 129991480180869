$(window).on('load', function(){
    $('body').addClass('js-loaded');
    $('#mv').slick({
        fade: true,
        speed: 3000,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false,
        adaptiveHeight: true,
        adaptiveWidth: true,
    });
    setTimeout(function(){
        $('#mv').slick('slickPlay');
    },4000);

    $('[data-submenu]').on({
        'mouseenter': function(){
            $(this).find('[data-submenu-list]').addClass('js-active');
        },
        'mouseleave': function(){
            $(this).find('[data-submenu-list]').removeClass('js-active');
        }
    });
});